<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Geral</span>
      </template>

      <account-setting-general :dataProp="users[0]" @fetch-details="fetchDetails" v-if="isLoading == false && users[0]"/>
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Mudar senha</span>
      </template>

      <account-setting-password/>
    </b-tab>
    <!--/ change password tab -->
  </b-tabs>
</template>

<script>
/* eslint-disable */
import { BTabs, BTab } from 'bootstrap-vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingInformation from './AccountSettingInformation.vue'
import { mapActions, mapState } from "vuex";

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingInformation
  },
  data() {
    return {
      options: {},
      isLoading: true,
    }
  },
  computed: {
    ...mapState("users", ["users"]),
  },
  created(){
    this.fetchDetails();
  },
  methods: {
    fetchDetails(paginated = false, page = 1){
      this.isLoading = true
      this.$store.dispatch("users/fetchDetails", {
          users_id: JSON.parse(localStorage.getItem('userData')).id,
          paginate: paginated,
          page: page,
        }).finally(() => {
          this.isLoading = false
        });
    },
  }
}
</script>
