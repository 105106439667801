<template>
  <b-card>
    <b-overlay :show="isLoading" opacity="0.70" variant="transparent" rounded="sm">
    <div class="divider my-2">
      <div class="divider-text">
        <h4 class="text-center">Configurações de notificação</h4>
      </div>
    </div>
    <b-row>
      <b-col>
        <b-form-group
          label="Receber notificação criada pelo professor:"
          label-for="i-nota"
          class="pl-1 pr-1 text-center"
        >
          <b-form-checkbox
            checked="true"
            class="custom-control-primary"
            name="check-button"
            switch
            v-model="notify_when_creating_manual_notice"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </b-form-group>
      </b-col>
       <b-col>
        <b-form-group
          label="Receber notificação ao corrigir a atividade:"
          label-for="i-nota"
          class="pl-1 pr-1 text-center"
        >
          <b-form-checkbox
            checked="true"
            class="custom-control-primary"
            name="check-button"
            switch
            v-model="notify_when_correcting_activity"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </b-form-group>
      </b-col>
       <b-col>
        <b-form-group
          label="Receber notificação se a atividade atrasar:"
          label-for="i-nota"
          class="pl-1 pr-1 text-center"
        >
          <b-form-checkbox
            checked="true"
            class="custom-control-primary"
            name="check-button"
            switch
            v-model="notify_when_activity_delay"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </b-form-group>
      </b-col>
       <b-col>
        <b-form-group
          label="Receber notificação ao postar no mural:"
          label-for="i-nota"
          class="pl-1 pr-1 text-center"
        >
          <b-form-checkbox
            checked="true"
            class="custom-control-primary"
            name="check-button"
            switch
            v-model="notify_wall_post"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
    </b-overlay>
  </b-card>
</template>

<script>
/* eslint-disable */
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import { mapActions, mapState } from "vuex";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    vSelect,
    flatPickr,
    Cleave,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      countryOption: ['USA', 'India', 'Canada'],
      clevePhone: {
        phone: true,
        phoneRegionCode: 'US',
      },
      isLoading: false,
      dataSettings: true,
      notify_when_creating_manual_notice: true,
      notify_when_correcting_activity: true,
      notify_when_activity_delay: true,
      notify_wall_post: true
    }
  },
   watch: {
    notify_when_creating_manual_notice(){
      this.updateSetting()
    },
    notify_when_correcting_activity(){
      this.updateSetting()
    },
    notify_when_activity_delay(){
      this.updateSetting()
    },
    notify_wall_post(){
      this.updateSetting()
    },
  },
  computed: {
    ...mapState("settings", ["settings"]),
  },
  created(){
    this.fetchSettings();
  },
  methods: {
    ...mapActions("settings", ["update"]),

    updateSetting(){
      this.isLoading = true
      this.dataSettings = {
        notify_when_creating_manual_notice: this.notify_when_creating_manual_notice == true ? 1 : 0,
        notify_when_correcting_activity: this.notify_when_correcting_activity == true ? 1 : 0,
        notify_when_activity_delay: this.notify_when_activity_delay == true ? 1 : 0,
        notify_wall_post: this.notify_wall_post == true ? 1 : 0
      }
      this.update({
        ...this.dataSettings,
      })
      .then(() => {
        this.fetchSettings()
      })
      .finally(() => {
        this.isLoading = false
      })
    },

    fetchSettings() {
      this.$store
        .dispatch("settings/fetchNotifications")
        .then((response) => {
          this.totalRows = response.total;
          this.notify_when_creating_manual_notice = response.notify_when_creating_manual_notice == 1 ? true : false,
          this.notify_when_correcting_activity = response.notify_when_correcting_activity  == 1 ? true : false,
          this.notify_when_activity_delay = response.notify_when_activity_delay  == 1 ? true : false,
          this.notify_wall_post = response.notify_wall_post  == 1 ? true : false
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
        });
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
