<template>
  <b-card>
    <!-- form -->
    <b-form>
      <validation-observer ref="simpleRules">
      <b-row>
        <!-- old password -->
        <b-col md="6">
          <b-form-group
            label="Senha antiga"
            label-for="account-old-password"
          >
            <validation-provider
              #default="{ errors }"
              name="senha antiga"
              rules="required"
            >
              <b-form-input
                id="account-old-password"
                name="old-password"
                type="password"
                placeholder="Senha antiga"
                v-model="dataPasswords.old_password"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!--/ old password -->
      </b-row>
      <b-row>
        <!-- new password -->
        <b-col md="6">
          <b-form-group
            label-for="account-new-password"
            label="Nova senha"
          >
            <validation-provider
              #default="{ errors }"
              name="senha"
              vid="Password"
              rules="required"
            >
              <b-form-input
                id="account-new-password"
                type="password"
                name="new-password"
                placeholder="Nova senha"
                v-model="dataPasswords.password"
                autocomplete="off"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!--/ new password -->

        <!-- retype password -->
        <b-col md="6">
          <b-form-group
            label-for="account-retype-new-password"
            label="Confirme sua nova senha"
          >
          <validation-provider
              #default="{ errors }"
              name="confime sua senha"
              rules="required|confirmed:Password"
            >
              <b-form-input
                id="account-retype-new-password"
                type="password"
                name="retype-password"
                placeholder="Confirme sua nova senha"
                v-model="dataPasswords.password_confirmation"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!--/ retype password -->

        <!-- buttons -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click="updatePassword"
          >
            Salvar senha
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
      </validation-observer>
    </b-form>
  </b-card>
</template>

<script>
/* eslint-disable */
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapState } from "vuex";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required, email, confirmed, password,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      dataPasswords: {
        old_password: null,
        password: null,
        password_confirmation: null,
      },
      isLoading: true
    }
  },
  computed: {
  },
  methods: {
    updatePassword(paginated = false, page = 1){
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isLoading = true
          this.$store.dispatch("auth/newPassword", this.dataPasswords)
          .then((response) => { 
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Senha atualizado com sucesso!',
                icon: 'LockIcon',
                text: 'Operação executada com sucesso.',
                variant: 'info'
              }
            })
            this.dataPasswords.old_password = null,
            this.dataPasswords.password = null,
            this.dataPasswords.password_confirmation = null
            this.$nextTick(() => this.$refs.simpleRules.reset());
          })
          .finally(() => {
            this.isLoading = false
          });
        }else{
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro de validação!',
              icon: 'AlertTriangleIcon',
              text: 'Por favor preencha todos os campos obrigatorios.',
              variant: 'danger'
            }
          })
        }
      })
    },
  },
}
</script>
