<template>
  <b-card>
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-avatar
            :src="dataUser.photo"
            size="50"
            class="mr-1"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-row v-if="showUpdatePhoto == false">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mb-75 mr-75"
            @click="showUpdate(true)"
          >
            Atualizar foto
          </b-button>
          <!--/ upload button -->
        </b-row>
        <b-row v-if="showUpdatePhoto == true">
          <b-col lg="5">
            <b-form-file
              v-model="photo"
              placeholder="Escolha um arquivo ou solte-o aqui..."
              drop-placeholder="Solte o arquivo aqui..."
              type="file"
              accept="image/*"
            />
          </b-col>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mb-75 mr-75 mt-0"
              @click="updatePhotos()"
            >
              Atualizar
            </b-button>
          </b-col>
        </b-row>
      </b-media-body>
    </b-media>
    <!--/ media -->
    <hr>
    <div class="mt-2">
      <validation-observer ref="simpleRules">
      <b-row>
        <b-col>
          <b-form-group
            label="Usuário de Acesso"
            label-for="account-username"
          >
            <validation-provider
              #default="{ errors }"
              name="usuário de acesso"
              rules="required"
            >
              <b-form-input
                v-model="dataUser.username"
                placeholder="Username"
                name="username"
                disabled
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Nome"
            label-for="account-name"
          >
            <validation-provider
              #default="{ errors }"
              name="nome"
              rules="required"
            >
              <b-form-input
                v-model="dataUser.name"
                name="name"
                placeholder="Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="E-mail"
            label-for="account-e-mail"
          >
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="required"
            >
              <b-form-input
                v-model="dataUser.email"
                name="email"
                placeholder="Email"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="updateGeral"
          >
            Salvar alterações
          </b-button>
        </b-col>
      </b-row>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { mapActions, mapState } from "vuex";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required, email, confirmed, password,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: { 
    dataProp: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      profileFile: null,
      dataUser: {},
      showUpdatePhoto: false,
      photo: null,
    }
  },
  computed: {
    ...mapState("users", ["users"]),
  },
  methods: {
    ...mapActions("users", ["update", "updatePhoto"]),

    showUpdate(value){
      this.showUpdatePhoto = value;
    },

    updatePhotos(){
      this.updatePhoto({photo: this.photo})
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Atualizado com sucesso!',
              icon: 'ThumbsUpIcon',
              text: 'Operação executada com sucesso.',
              variant: 'info'
            }
          })
          this.$emit('fetch-details');
        })
        .finally(() => {
        })
    },
    
    updateGeral(){
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.update(this.dataUser)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Atualizado com sucesso!',
                icon: 'ThumbsUpIcon',
                text: 'Operação executada com sucesso.',
                variant: 'info'
              }
            })
            this.$nextTick(() => this.$refs.simpleRules.reset());
            this.$emit('fetch-details');
          })
          .finally(() => {
          })
        }else{
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro de validação!',
              icon: 'AlertTriangleIcon',
              text: 'Por favor preencha todos os campos obrigatorios.',
              variant: 'danger'
            }
          })
        }
      })
    }

  },
  watch: {
    dataProp: {
      immediate: true, 
      handler (val, oldVal) {
        if (!val) return; 
          this.dataUser.id = this.dataProp.id;
          this.dataUser.email = this.dataProp.email;
          this.dataUser.name = this.dataProp.name;
          this.dataUser.username = this.dataProp.username;
          this.dataUser.photo = this.dataProp.photo;
      }
    }
  }
}
</script>
